// App.js
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useLoginStore, useMainStore } from './store';
import Login from './pages/Login';
import Projects from './pages/Projects';
import Project from './pages/Project';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Loader from './components/Loader';
import Sidebar from './components/Sidebar';
import { SidebarProvider } from './context/SidebarContext';
import classNames from 'classnames';

import './App.scss';

function App() {
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const checkToken = useLoginStore((state) => state.checkToken);
  const loading = useMainStore((state) => state.loading);

  useEffect(() => {
    const check = async () => {
      await checkToken();
      setIsCheckingToken(false);
    };
    check();
  }, [checkToken]);

  return (
    <div
      id="rgbc-wp-pm-tool"
      className={classNames('h-full px-4 py-8', { loading })}
    >
      <div className="container flex flex-col mx-auto h-full bg-white rounded-md shadow-sm relative">
        <SidebarProvider>
          <Router>
            <Header />
            <div className="p-4 flex-1 main-area">
              {!isCheckingToken && (
                <Routes>
                  <Route path="/" element={<Navigate to="/login" replace />} />
                  <Route
                    path="/login"
                    element={
                      isLoggedIn ? <Navigate to="/projects" /> : <Login />
                    }
                  />
                  <Route
                    path="/projects"
                    element={
                      !isLoggedIn ? <Navigate to="/login" /> : <Projects />
                    }
                  />
                  <Route
                    path="/projects/:id"
                    element={
                      !isLoggedIn ? <Navigate to="/login" /> : <Project />
                    }
                  >
                    <Route path="types" element={<></>} />
                    <Route path="taxonomies" element={<></>} />
                    <Route path="menus" element={<></>} />
                    <Route path="field_groups" element={<></>}>
                      <Route path=":groupName" element={<></>} />
                    </Route>
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              )}
            </div>
          </Router>
          {loading && <Loader />}
          {isLoggedIn && <Sidebar />}
        </SidebarProvider>
      </div>
    </div>
  );
}

export default App;
