import Entity, { CORE_TAXONOMIES, CORE_TYPES } from './Entity';
import Settings from './Settings';
import FieldGroup from './FieldGroup';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { saveAs } from 'file-saver';
import {
  registerPostTypes,
  registerTaxonomies,
  registerMenus,
  registerAcf,
} from '../../helpers';
import { useProjectsStore } from '../../store';

const Entities = ({ project, group }) => {
  const addEntity = useProjectsStore((state) => state.addEntity);
  const selectEntity = useProjectsStore((state) => state.selectEntity);
  const setProjectProperty = useProjectsStore(
    (state) => state.setProjectProperty
  );

  let core = [];
  switch (project.view) {
    case 'types':
      core = CORE_TYPES;
      break;
    case 'taxonomies':
      core = CORE_TAXONOMIES;
      break;
    default:
      break;
  }

  const isCheckedAll = () => {
    const itemsLength = (project?.[project.view] || []).length;
    return (
      itemsLength &&
      project.selectedEntities?.length === itemsLength - core.length
    );
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      (project?.[project.view] || []).forEach((entity, index) => {
        if (
          !(project?.selectedEntities || []).includes(index) &&
          !core.includes(entity.slug)
        ) {
          selectEntity(project.id, index);
        }
      });
    } else {
      setProjectProperty(project.id, 'selectedEntities', []);
    }
  };

  return (
    <div className="flex-1 -mr-4 h-full overflow-y-auto">
      {!project.view && <Settings project={project} />}
      {group && <FieldGroup project={project} group={group} />}
      {!group && (
        <>
          {project.view && (
            <table className="table-fixed border-collapse w-full bg-slate-100 font-semibold">
              <tbody>
                <tr className="border-b border-slate-200 border-solid">
                  <td className="w-8 p-2"></td>
                  <td className="w-8 p-2">
                    <Checkbox
                      checked={isCheckedAll()}
                      title="Check all"
                      onChange={checkAll}
                    />
                  </td>
                  <td className="p-2">Name</td>
                </tr>
              </tbody>
            </table>
          )}
          <table className="table-fixed border-collapse w-full">
            <tbody>
              {(project?.[project.view] || []).map((entity, index) => {
                return (
                  <Entity
                    key={index}
                    project={project}
                    entity={{ ...entity, index }}
                  />
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-between p-2">
            {project.view && (
              <Button
                text="Add"
                onClick={() => {
                  addEntity(project.id, project.view);
                }}
              />
            )}
            {project.selectedEntities.length > 0 && (
              <Button
                text="Export"
                onClick={() => {
                  let phpCode = '';
                  switch (project.view) {
                    case 'types':
                      phpCode = registerPostTypes(project);
                      break;
                    case 'taxonomies':
                      phpCode = registerTaxonomies(project);
                      break;
                    case 'menus':
                      phpCode = registerMenus(project);
                      break;
                    case 'field_groups':
                      phpCode = registerAcf(project);
                      break;
                    default:
                      break;
                  }

                  const blob = new Blob([phpCode], {
                    type: 'text/plain;charset=utf-8',
                  });
                  saveAs(blob, 'custom_post_types.php');
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Entities;
