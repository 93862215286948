import Rule from './Rule';
import { useProjectsStore } from '../../../../store';
import Button from '../../../../components/Button';

const GroupLocation = ({ project, entity }) => {
  const addLocationGroup = useProjectsStore((state) => state.addLocationGroup);
  return (
    <div className="text-sm text-gray-700">
      <div className="mb-2">Show this field group if</div>
      {(entity?.location || []).map((rule_group, ruleGroupIndex) => {
        return (
          <div key={ruleGroupIndex}>
            {rule_group?.map((rule, ruleIndex) => (
              <Rule
                key={ruleIndex}
                project={project}
                fieldGroup={entity}
                ruleGroupIndex={ruleGroupIndex}
                rule={rule}
                ruleIndex={ruleIndex}
              />
            ))}
            <strong className="my-2 font-semibold block">or</strong>
            {ruleGroupIndex === entity?.location?.length - 1 && (
              <Button
                text="Add rule group"
                className="text-xs px-2 py-1"
                onClick={() => {
                  addLocationGroup(project.id, entity.id);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GroupLocation;
