import React, { useState, useRef, useEffect } from 'react';

import './ConfirmBtn.scss';

function ConfirmBtn({ onClick, children }) {
  const [showModal, setShowModal] = useState(false);
  const btnRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showModal && btnRef.current && modalRef.current) {
        if (
          !btnRef.current.contains(event.target) &&
          !modalRef.current.contains(event.target)
        ) {
          setShowModal(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  return (
    <div className="relative inline-block">
      <button
        ref={btnRef}
        className=""
        onClick={() => setShowModal(!showModal)}
      >
        {children}
      </button>

      {showModal && (
        <div
          ref={modalRef}
          className="absolute bottom-full mb-2 w-28 z-50 confirm-btn--dialog"
          style={{ minWidth: btnRef.current ? btnRef.current.offsetWidth : 0 }}
        >
          <div className="bg-white p-2 shadow-lg text-center">
            <p className="mb-2 text-sm">Are You sure?</p>
            <div className="flex justify-center space-x-2">
              <button
                className="text-xs mr-4 hover:text-red-500"
                onClick={() => setShowModal(false)}
              >
                No
              </button>
              <button
                className="text-xs hover:text-teal-500"
                onClick={() => {
                  onClick();
                  setShowModal(false);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmBtn;
