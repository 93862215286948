import { CORE_TAXONOMIES, CORE_TYPES } from '../pages/Project/Entity';
import { explodeChoices } from './index';

export const registerPostTypes = (project) => {
  const { view, selectedEntities, types } = project;

  if (view !== 'types' || !selectedEntities.length) return '';

  let phpCode = '<?php\n\n';

  selectedEntities.forEach((type) => {
    const typeData = types[type];
    if (typeData) {
      const {
        name,
        singular_name,
        menu_name,
        slug,
        rewrite,
        description,
        hierarchical,
        has_archive,
        public: isPublic,
        taxonomies,
        supports,
        rest_base,
        rest_namespace,
        icon = 'admin-post',
      } = typeData;

      phpCode += `
        function create_${slug}_post_type() {
          register_post_type( '${slug}',
            array(
              'labels' => array(
                'name' => __( '${name}' ),
                'singular_name' => __( '${singular_name || name}' ),
                'menu_name' => __( '${menu_name || name}' ),
              ),
              'description' => '${description}',
              'hierarchical' => ${hierarchical},
              'has_archive' => ${has_archive},
              'public' => ${isPublic},
              'rewrite' => ${
                rewrite ? `array('slug' => '${rewrite}')` : 'true'
              },
              'taxonomies'  => array( '${taxonomies.join("', '")}' ),
              'supports'  => array( '${supports.join("', '")}' ),
              'rest_base' => '${rest_base}',
              'rest_namespace' => '${rest_namespace}',
              'icon' => '${icon}',
            )
          );
        }
        add_action( 'init', 'create_${slug}_post_type' );
      `;
    }
  });

  phpCode += '\n';

  return phpCode;
};

export const registerTaxonomies = (project) => {
  const { view, selectedEntities, taxonomies } = project;

  if (view !== 'taxonomies' || !selectedEntities.length) return '';

  let phpCode = '<?php\n\n';

  selectedEntities.forEach((taxonomy) => {
    const taxonomyData = taxonomies[taxonomy];
    if (taxonomyData) {
      const {
        name,
        slug,
        description,
        hierarchical,
        types: assignedTypes,
      } = taxonomyData;

      phpCode += `
        function create_${slug}_taxonomy() {
          $labels = array(
            'name' => __( '${name}' ),
            'singular_name' => __( '${name}' ),
          );
          
          $args = array(
            'labels' => $labels,
            'description' => '${description}',
            'hierarchical' => ${hierarchical},
          );
          
          register_taxonomy( '${slug}', ${JSON.stringify(assignedTypes).replace(
        /"/g,
        "'"
      )}, $args );
        }
        add_action( 'init', 'create_${slug}_taxonomy' );
      `;
    }
  });

  phpCode += '\n';

  return phpCode;
};

export const registerMenus = (project) => {
  const { view, selectedEntities, menus } = project;

  if (view !== 'menus' || !selectedEntities.length) return '';

  let phpCode = '<?php\n\n';

  phpCode += `
  add_action( 'after_setup_theme', 'theme_setup' );

  function theme_setup() {
    register_nav_menus(
      array(
  `;

  selectedEntities.forEach((menu) => {
    const menuData = menus[menu];
    if (menuData) {
      const { name, slug } = menuData;

      phpCode += `'${slug}' => esc_html__( '${name}', 'rgbc-theme' ),\n`;
    }
  });

  phpCode += ')\n);\n}\n';

  return phpCode;
};

export const registerAcf = (project) => {
  const { view, selectedEntities, field_groups } = project;

  if (view !== 'field_groups' || !selectedEntities.length) return '';

  let phpCode = '<?php\n\n';

  phpCode += `add_action( 'acf/init', 'register_fields' );

function register_fields() {
  if (! function_exists( 'acf_add_local_field_group' )) {
    return;
  }\n`;

  selectedEntities.forEach((group) => {
    const groupData = field_groups[group];
    if (groupData) {
      const fieldsCode = groupData?.fields
        .map((field) => {
          let additionalProps = '';

          ['allow_null', 'multiple', 'return_format', 'layout'].forEach(
            (prop) => {
              if (typeof field?.[prop] !== 'undefined') {
                additionalProps += `'${prop}' => ${
                  Number.isInteger(field[prop])
                    ? field[prop]
                    : `'${field[prop]}'`
                },\n`;
              }
            }
          );

          if (field.choices && typeof field.choices === 'string') {
            const choicesObj = explodeChoices(field.choices);
            const choicesStr = Object.entries(choicesObj)
              .map(([key, value]) => `'${key}' => '${value}'`)
              .join(',\n');
            additionalProps += `'choices' => array(\n${choicesStr}\n),\n`;
          }

          return `array(
            'key' => '${field.key}',
            'label' => '${field.label}',
            'name' => '${field.name}',
            'type' => '${field.type}',
            'required' => ${field.required},
            'instructions' => '${field.instructions}',
            'default_value' => '${field.default_value}',
            ${additionalProps}
          )`;
        })
        .join(',\n');

      const locationsCode = groupData.location
        .map((locationArr) => {
          const locationArrCode = locationArr
            .map((location) => {
              return `array(
              'param' => '${location.param}',
              'operator' => '${location.operator}',
              'value' => '${location.value}'
            )`;
            })
            .join(',\n');
          return `array(\n${locationArrCode}\n)`;
        })
        .join(',\n');

      phpCode += `  acf_add_local_field_group(
        array(
          'key' => '${groupData.key}',
          'title' => '${groupData.name}',
          'fields' => array(
            ${fieldsCode}
          ),
          'location' => array(
            ${locationsCode}
          ),
        )
      );\n`;
    }
  });

  phpCode += '}';

  return phpCode;
};

export const registerAll = (project) => {
  const curProject = { ...project, view: 'types', selectedEntities: [] };
  project.types.forEach((type, index) => {
    if (!CORE_TYPES.includes(type.slug)) {
      curProject.selectedEntities.push(index);
    }
  });

  let phpCode = '';

  const typesCode = registerPostTypes(curProject);

  if (typesCode.trim() !== '') {
    phpCode += typesCode;
    phpCode += '\n?>\n';
  }

  curProject.view = 'taxonomies';
  curProject.selectedEntities = [];
  project.taxonomies.forEach((taxonomy, index) => {
    if (!CORE_TAXONOMIES.includes(taxonomy.slug)) {
      curProject.selectedEntities.push(index);
    }
  });
  const taxCode = registerTaxonomies(curProject);

  if (taxCode.trim() !== '') {
    phpCode += taxCode;
    phpCode += '\n?>\n';
  }

  curProject.view = 'menus';
  curProject.selectedEntities = project.menus.reduce(
    (previousValue, currentValue, currentIndex) => {
      return [...previousValue, currentIndex];
    },
    []
  );
  const menusCode = registerMenus(curProject);

  if (menusCode.trim() !== '') {
    phpCode += menusCode;
    phpCode += '\n?>\n';
  }

  curProject.view = 'field_groups';
  curProject.selectedEntities = project.field_groups.reduce(
    (previousValue, currentValue, currentIndex) => {
      return [...previousValue, currentIndex];
    },
    []
  );
  const acfCode = registerAcf(curProject);

  if (acfCode.trim() !== '') {
    phpCode += acfCode;
    phpCode += '\n?>\n';
  }

  return phpCode;
};
