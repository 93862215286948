import classNames from 'classnames';

const Button = (props) => {
  const {
    type = 'button',
    text = '',
    onClick = () => {},
    isDisabled = false,
    className = '',
    isSmall = false,
  } = props;
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(
        'rounded-md bg-sky-600 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600',
        className,
        {
          'px-2 py-1 text-xs font-normal': isSmall,
          'px-3.5 py-2.5 text-sm font-semibold': !isSmall,
        }
      )}
    >
      {text}
    </button>
  );
};

export default Button;
