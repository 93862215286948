import Checkbox from '../../../components/Checkbox';
import { useProjectsStore } from '../../../store';

const MAIN_ARGS = {
  hierarchical: 'Hierarchical',
  has_archive: 'Has archive',
  public: 'Public',
};

const PostType = ({ isDefault, project, entity }) => {
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  return (
    <>
      {Object.entries(MAIN_ARGS).map(([arg, label]) => {
        return (
          <td key={arg}>
            <Checkbox
              label={label}
              checked={entity?.[arg] || false}
              onChange={(e) => {
                changeEntityProperty(
                  project.id,
                  'types',
                  entity.index,
                  arg,
                  e.target.checked
                );
              }}
              isReadonly={isDefault}
              switcher
            />
          </td>
        );
      })}
    </>
  );
};

export default PostType;
