import React from 'react';
import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import TextArea from '../../../components/TextArea';
import Select from 'react-select';
import { useProjectsStore } from '../../../store';

import FIELD_TYPES from './field-types.json';

const RETURN_FORMATS = [
  { label: 'Value', value: 'value' },
  { label: 'Label', value: 'label' },
  { label: 'Both (Array)', value: 'array' },
];

const LAYOUTS = [
  { label: 'Vertical', value: 'vertical' },
  { label: 'Horizontal', value: 'horizontal' },
];

const Edit = ({ index, field, project, group }) => {
  const { changeFieldProp } = useProjectsStore();
  return (
    <div className="p-4 bg-white">
      <Input
        type="text"
        label="Field label"
        value={field?.label || ''}
        onChange={(e) => {
          changeFieldProp(
            project.id,
            group.index,
            index,
            'label',
            e.target.value
          );
        }}
      />
      <Input
        type="text"
        label="Field name"
        value={field?.name || ''}
        onChange={(e) => {
          changeFieldProp(
            project.id,
            group.index,
            index,
            'name',
            e.target.value
          );
        }}
        sanitize="slug"
      />
      <div className="text-sm text-gray-700">Field type</div>
      <Select
        options={FIELD_TYPES}
        onChange={(opt) => {
          changeFieldProp(project.id, group.index, index, 'type', opt.value);
        }}
        value={
          FIELD_TYPES.filter((type) => type.value === field.type)?.[0] || null
        }
      />
      <Checkbox
        checked={field.required === 1}
        label="Required"
        labelLeft
        classes="my-4"
        onChange={(e) => {
          changeFieldProp(
            project.id,
            group.index,
            index,
            'required',
            e.target.checked ? 1 : 0
          );
        }}
        switcher
      />
      {field.type === 'select' && (
        <div>
          <Checkbox
            checked={field?.multiple === 1}
            label="Multiple"
            labelLeft
            classes="my-4"
            onChange={(e) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'multiple',
                e.target.checked ? 1 : 0
              );
            }}
            switcher
          />
        </div>
      )}
      {['select', 'radio'].indexOf(field.type) !== -1 && (
        <div>
          <Checkbox
            checked={field?.allow_null === 1}
            label="Allow Null"
            labelLeft
            classes="my-4"
            onChange={(e) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'allow_null',
                e.target.checked ? 1 : 0
              );
            }}
            switcher
          />
        </div>
      )}
      {['checkbox', 'select', 'radio'].indexOf(field.type) !== -1 && (
        <div>
          <TextArea
            label="Choices"
            value={field?.choices || ''}
            onChange={(e) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'choices',
                e.target.value
              );
            }}
          />
          <TextArea
            label="Default value"
            value={field?.default_value || ''}
            onChange={(e) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'default_value',
                e.target.value
              );
            }}
          />
          <div className="text-sm text-gray-700">Return format</div>
          <Select
            options={RETURN_FORMATS}
            onChange={(opt) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'return_format',
                opt.value
              );
            }}
            value={
              RETURN_FORMATS.filter(
                (format) => format.value === field.return_format
              )?.[0] || null
            }
            className="mb-4"
          />
        </div>
      )}
      {['checkbox', 'radio'].indexOf(field.type) !== -1 && (
        <div>
          <div className="text-sm text-gray-700">Layout</div>
          <Select
            options={LAYOUTS}
            onChange={(opt) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'layout',
                opt.value
              );
            }}
            value={
              LAYOUTS.filter((layout) => layout.value === field.layout)?.[0] ||
              null
            }
            className="mb-4"
          />
        </div>
      )}
      {['text', 'number'].indexOf(field.type) !== -1 && (
        <Input
          type={field.type === 'number' ? field.type : 'text'}
          label="Default value"
          value={field?.default_value || ''}
          onChange={(e) => {
            changeFieldProp(
              project.id,
              group.index,
              index,
              'default_value',
              e.target.value
            );
          }}
        />
      )}
      {field.type === 'true_false' && (
        <div>
          <Checkbox
            checked={field.default_value === 1}
            label="Default value"
            labelLeft
            classes="mb-4"
            onChange={(e) => {
              changeFieldProp(
                project.id,
                group.index,
                index,
                'default_value',
                e.target.checked ? 1 : 0
              );
            }}
            switcher
          />
        </div>
      )}
      <TextArea
        label="Instructions"
        value={field?.instructions || ''}
        onChange={(e) => {
          changeFieldProp(
            project.id,
            group.index,
            index,
            'instructions',
            e.target.value
          );
        }}
      />
    </div>
  );
};

export default Edit;
