import React, { useMemo } from 'react';
import Input from '../../../components/Input';
import Select from 'react-select';
import { useProjectsStore } from '../../../store';

const TaxonomySettings = ({ isDefault, project, entityID }) => {
  const projects = useProjectsStore((state) => state.projects);
  const entity = projects[project.id].taxonomies?.[entityID];
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  const options = useMemo(() => {
    if (project && Array.isArray(project.types)) {
      return project.types.map((type) => ({
        value: type.slug,
        label: type.name,
      }));
    }
    return [];
  }, [project]);
  const selectedTypes = useMemo(() => {
    if (entity && Array.isArray(entity.types)) {
      return options.filter((option) => entity.types.includes(option.value));
    }
    return [];
  }, [entity, options]);
  return (
    <div className="flex flex-col">
      <Input
        type="text"
        value={entity?.slug || ''}
        label="Slug"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'taxonomies',
              entityID,
              'slug',
              e.target.value
            );
          }
        }}
        sanitize="slug"
      />
      <Input
        type="text"
        value={entity?.name || ''}
        label="Name"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'taxonomies',
              entityID,
              'name',
              e.target.value
            );
          }
        }}
      />
      <div className="text-sm text-gray-700 mb-1">Post types</div>
      <Select
        options={options}
        isMulti
        onChange={(values) => {
          const flattened = values.map((opt) => opt.value);
          changeEntityProperty(
            project.id,
            'taxonomies',
            entityID,
            'types',
            flattened
          );
        }}
        value={selectedTypes}
      />
    </div>
  );
};

export default TaxonomySettings;
