// Project.js
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Structure from './Structure';
import Entities from './Entities';
import Pagination from './Pagination';
import { useCurrentProject } from '../../hooks';

import './Project.scss';

function Project() {
  const navigate = useNavigate();
  const project = useCurrentProject();

  useEffect(() => {
    if (!project) {
      navigate('/projects');
    }
  }, [project, navigate]);

  const { groupName } = useParams();

  const group = useMemo(() => {
    let index = null;
    const find = groupName
      ? project?.field_groups?.filter((group, i) => {
          if (group.slug === groupName) {
            index = i;
            return true;
          }
          return false;
        })?.[0]
      : null;
    if (find && index !== null) {
      return { ...find, index: index };
    }
    return null;
  }, [project, groupName]);

  return (
    project && (
      <div className="flex flex-col h-full">
        <Pagination project={project} group={group} />
        <div className="flex flex-1 -mb-4 project-area">
          <Structure project={project}></Structure>
          <Entities project={project} group={group}></Entities>
        </div>
      </div>
    )
  );
}

export default Project;
