import { useMemo } from 'react';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const Acf = ({ project, entity, closeSidebar }) => {
  const navigate = useNavigate();

  const locations = useMemo(() => {
    const arr =
      entity?.location?.reduce((acc, arr) => {
        const arrValues = arr.map((item) => item.value);
        return [...acc, ...arrValues];
      }, []) || [];
    return arr.join(', ');
  }, []);

  return (
    <>
      <td>
        <button
          className="inline-flex items-center text-gray-700 hover:text-sky-600"
          onClick={() => {
            closeSidebar();
            navigate(`/projects/${project.id}/field_groups/${entity.slug}`);
          }}
        >
          Fields: {entity?.fields?.length || 0}
          <ArrowLeftOnRectangleIcon className="h-5 w-5 ml-2 " />
        </button>
      </td>
      <td>Location: {locations}</td>
    </>
  );
};

export default Acf;
