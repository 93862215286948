import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useProjectsStore } from '../../../store';
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import ConfirmBtn from '../../../components/ConfirmBtn';
import Edit from './Edit';

const Field = ({ index, field, project, group, newField }) => {
  const { removeFieldByIndex, duplicateField } = useProjectsStore();
  const [isEdit, setIsEdit] = useState(false);
  // Open edit form for the new field.
  useEffect(() => {
    if (index === newField) {
      setIsEdit(true);
    }
  }, [index]);

  return (
    <>
      <tr
        className={classNames('border-b border-slate-200 border-solid', {
          'bg-emerald-50 border-b-0': isEdit,
          'hover:bg-slate-50': !isEdit,
        })}
      >
        <td className="p-2">{index + 1}</td>
        <td className="p-2">{field.label}</td>
        <td className="p-2 overflow-hidden text-ellipsis">{field.name}</td>
        <td className="p-2">{field.type}</td>
        <td className="p-2">
          <button
            type="button"
            title="Edit"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            {isEdit && (
              <PencilSquareIcon className="h-4 w-4 mr-2 text-gray-700 hover:text-teal-500" />
            )}
            {!isEdit && (
              <PencilIcon className="h-4 w-4 mr-2 text-gray-700 hover:text-teal-500" />
            )}
          </button>
          <button
            type="button"
            title="Duplicate"
            onClick={() => {
              duplicateField(project.id, group.index, index);
            }}
          >
            <DocumentDuplicateIcon className="h-4 w-4 mr-2 text-gray-700 hover:text-blue-500" />
          </button>
          <ConfirmBtn
            title="Delete"
            onClick={() => {
              removeFieldByIndex(project.id, group.index, index);
            }}
            children={
              <TrashIcon className="h-4 w-4 text-gray-700 hover:text-red-500" />
            }
          />
        </td>
      </tr>
      {isEdit && (
        <tr className="bg-emerald-50 border-b border-slate-200 border-solid">
          <td className="px-2 pb-2" colSpan="5">
            <Edit index={index} field={field} project={project} group={group} />
          </td>
        </tr>
      )}
    </>
  );
};

export default Field;
