import React from 'react';
import Input from '../../components/Input';
import { useProjectsStore } from '../../store';

const Settings = ({ project }) => {
  const setProjectProperty = useProjectsStore(
    (state) => state.setProjectProperty
  );

  return (
    <div className="p-4">
      <Input
        type="text"
        value={project?.name || ''}
        label="Project Name"
        onChange={(e) => {
          setProjectProperty(project.id, 'name', e.target.value);
        }}
      />
      <Input
        type="url"
        value={project?.url || ''}
        label="Project URL"
        onChange={(e) => {
          setProjectProperty(project.id, 'url', e.target.value);
        }}
        sanitize="url"
      />
    </div>
  );
};

export default Settings;
