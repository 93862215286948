import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useLoginStore = create(
  devtools((set) => ({
    isLoggedIn: false,
    setLoginState: (state) => set({ isLoggedIn: state }),
    checkToken: () => {
      const userData = JSON.parse(localStorage.getItem('rgbcWpPmToolUserData'));
      set({ isLoggedIn: !!userData?.token });
    },
  }))
);
