import { useParams, useLocation } from 'react-router-dom';
import { useProjectsStore } from '../store';

export const useCurrentProject = () => {
  const { id } = useParams();
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const subPage = pathnameParts[3] || '';
  const projects = useProjectsStore((state) => state.projects);
  const project = projects?.[id];

  if (project) {
    return { ...project, id, view: subPage };
  } else {
    return null;
  }
};
