import { useContext } from 'react';
import { SidebarContext } from '../../../context/SidebarContext';
import Checkbox from '../../../components/Checkbox';
import ConfirmBtn from '../../../components/ConfirmBtn';
import { useProjectsStore, useSidebarStore } from '../../../store';
import {
  EllipsisHorizontalIcon,
  XMarkIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import PostType from './PostType';
import PostTypeSettings from './PostTypeSettings';
import Taxonomy from './Taxonomy';
import TaxonomySettings from './TaxonomySettings';
import MenuSettings from './MenuSettings';
import Acf from './Acf';
import AcfSettings from './AcfSettings';

export const CORE_TYPES = ['page', 'post'];
export const CORE_TAXONOMIES = ['category', 'post_tag'];

const Entity = ({ project, entity }) => {
  const selectEntity = useProjectsStore((state) => state.selectEntity);
  const unselectEntity = useProjectsStore((state) => state.unselectEntity);
  const removeEntity = useProjectsStore((state) => state.removeEntity);
  const isOpen = useSidebarStore((state) => state.isOpen);
  const openSidebar = useSidebarStore((state) => state.openSidebar);
  const closeSidebar = useSidebarStore((state) => state.closeSidebar);
  const { setContent } = useContext(SidebarContext);
  let isDefault = false;
  let QuickSettings = () => <></>;
  let Settings = () => <></>;
  switch (project.view) {
    case 'taxonomies':
      isDefault = CORE_TAXONOMIES.includes(entity.slug);
      QuickSettings = Taxonomy;
      Settings = TaxonomySettings;
      break;
    case 'types':
      isDefault = CORE_TYPES.includes(entity.slug);
      QuickSettings = PostType;
      Settings = PostTypeSettings;
      break;
    case 'menus':
      Settings = MenuSettings;
      break;
    case 'field_groups':
      QuickSettings = Acf;
      Settings = AcfSettings;
      break;
    default:
      break;
  }

  const toggleSidebar = (entityID) => {
    if (isDefault) {
      return false;
    }
    if (isOpen === entityID) {
      closeSidebar();
    } else {
      setContent(
        <Settings isDefault={isDefault} project={project} entityID={entityID} />
      );
      openSidebar(entityID);
    }
  };
  const SettingsIcon =
    isOpen === entity.index ? XMarkIcon : EllipsisHorizontalIcon;
  return (
    <tr className="p-4 border-b border-slate-200 border-solid hover:bg-slate-50">
      <td
        className="w-8 p-2 cursor-pointer"
        onClick={() => {
          toggleSidebar(entity.index);
        }}
      >
        {!isDefault && <SettingsIcon className="h-4 w-4 text-gray-700" />}
      </td>
      <td className="w-8 p-2">
        <Checkbox
          type="checkbox"
          isReadonly={isDefault}
          onChange={(e) => {
            e.target.checked
              ? selectEntity(project.id, entity.index)
              : unselectEntity(project.id, entity.index);
          }}
          checked={project?.selectedEntities?.includes(entity.index)}
        />
      </td>
      <td
        className="p-2 cursor-pointer"
        onClick={() => {
          toggleSidebar(entity.index);
        }}
      >
        {entity.name}
      </td>
      <QuickSettings
        isDefault={isDefault}
        entity={entity}
        project={project}
        closeSidebar={closeSidebar}
      />
      <td className="p-2 w-8">
        {!isDefault && (
          <ConfirmBtn
            title={`Delete ${entity.name}`}
            onClick={() => {
              removeEntity(project.id, project.view, entity.index);
            }}
            children={<TrashIcon className="h-4 w-4 text-red-500" />}
          />
        )}
      </td>
    </tr>
  );
};

export default Entity;
