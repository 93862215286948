export {
  registerPostTypes,
  registerTaxonomies,
  registerMenus,
  registerAcf,
  registerAll,
} from './phpCode';

export const explodeChoices = (val = '') => {
  const choices = {};
  const str = val.trim();
  const lines = str.split('\n');
  if (lines.length && lines[0] !== '') {
    lines.forEach((line) => {
      const pair = line.trim().split(':');
      choices[pair[0].trim()] =
        pair.length > 1 ? pair[1].trim() : pair[0].trim();
    });
  }
  return choices;
};

export const implodeChoices = (choices) => {
  let str = '';
  const entries = Object.entries(choices);
  entries.forEach(([value, label], index) => {
    const isLast = index + 1 === entries.length;
    str += value === label ? value : `${value} : ${label}`;
    str += !isLast ? '\n' : '';
  });
  return str;
};
