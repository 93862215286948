// pages/Login.js
import React, { useState } from 'react';
import { useMainStore, useLoginStore } from '../../store';
import Input from '../../components/Input';
import Button from '../../components/Button';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const setLoginState = useLoginStore((state) => state.setLoginState);
  const loading = useMainStore((state) => state.loading);
  const setLoading = useMainStore((state) => state.setLoading);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const response = await fetch(
      `${
        process.env.REACT_APP_WP_SITE_URL ||
        'https://wordpress-475722-3668846.cloudwaysapps.com'
      }/wp-json/jwt-auth/v1/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      }
    );

    const data = await response.json();
    setLoading(false);

    // if login was successful
    if (data && data.token) {
      setLoginState(true);
      localStorage.setItem('rgbcWpPmToolUserData', JSON.stringify(data));
    } else {
      // handle error
      setError(data.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          label="Username"
          isReadonly={loading}
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          label="Password"
          isReadonly={loading}
        />
        <Button type="submit" text="Log in" isDisabled={loading} />
        {error !== '' && (
          <p
            className="mt-2 text-sm text-red-600"
            id="email-error"
            dangerouslySetInnerHTML={{ __html: error }}
          ></p>
        )}
      </form>
    </div>
  );
}

export default Login;
