import { useNavigate, useLocation } from 'react-router-dom';
import PageTitle from '../PageTitle';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import { useLoginStore } from '../../store';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem('rgbcWpPmToolUserData'));
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const setLoginState = useLoginStore((state) => state.setLoginState);
  const isProjectPage = location.pathname.includes('/projects/');

  const logOut = () => {
    localStorage.removeItem('rgbcWpPmToolUserData');
    setLoginState(false);
    navigate('/login');
  };

  return (
    <header className="flex h-16 justify-between items-center bg-gradient-to-r from-sky-400 to-blue-600 text-white rounded-t-md p-4">
      <PageTitle isProjectPage={isProjectPage} />
      <div className="inline-flex items-center">
        {isLoggedIn && (
          <>
            <small className="font-semibold mr-2">
              {userData?.user_display_name || ''}
            </small>
            <button onClick={logOut} title="Logout">
              <ArrowRightOnRectangleIcon className="h-4 w-4 text-white" />
            </button>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
