import { useLocation } from 'react-router-dom';

const PageTitle = ({ isProjectPage }) => {
  const location = useLocation();

  let title = '';
  switch (location.pathname) {
    case '/login':
      title = 'Login';
      break;
    default:
      title = 'RGBC WP PM Tool';
      break;
  }

  return <h1 className="text-xl sm:text-2xl font-semibold">{title}</h1>;
};

export default PageTitle;
