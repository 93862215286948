import Checkbox from '../../../components/Checkbox';
import { useProjectsStore } from '../../../store';

const Taxonomy = ({ isDefault, project, entity }) => {
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  return (
    <td>
      <Checkbox
        label="Hierarchical"
        checked={entity?.hierarchical || false}
        onChange={(e) => {
          changeEntityProperty(
            project.id,
            'taxonomies',
            entity.index,
            'hierarchical',
            e.target.checked
          );
        }}
        isReadonly={isDefault}
        switcher
      />
    </td>
  );
};

export default Taxonomy;
