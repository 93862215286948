import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const uuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return s4() + s4() + s4() + s4() + s4() + s4();
};

const getDefaultData = () => {
  return {
    types: {
      name: 'New Post Type',
      slug: `cpt${Date.now()}`,
      description: '',
      hierarchical: false,
      has_archive: false,
      icon: null,
      taxonomies: [],
      supports: ['title', 'editor', 'author'],
      rest_base: 'new_type',
      rest_namespace: 'wp/v2',
    },
    taxonomies: {
      name: 'New Taxonomy',
      slug: `tax${Date.now()}`,
      description: '',
      types: [],
      hierarchical: false,
    },
    menus: {
      name: 'New Menu',
      slug: `menu${Date.now()}`,
    },
    field_groups: {
      name: 'New Field Group',
      slug: `fields_${Date.now()}`,
      key: `group_${uuid()}`,
      fields: [],
      location: [
        [
          {
            param: 'post_type',
            operator: '==',
            value: 'page',
          },
        ],
      ],
    },
    field: {
      key: `field_${uuid()}`,
      label: 'New field',
      name: `field_${uuid()}`,
      type: 'text',
      required: 0,
      instructions: '',
      default_value: '',
    },
  };
};

export const useProjectsStore = create(
  devtools((set) => ({
    projects: {
      1: {
        name: 'Project 1',
        url: 'rgbc1.com',
        loaded: true,
        selectedEntities: [],
        types: [
          {
            description: '',
            hierarchical: false,
            has_archive: false,
            public: true,
            name: 'Posts',
            slug: 'post',
            icon: 'dashicons-admin-post',
            taxonomies: ['category', 'post_tag'],
            supports: ['title', 'editor', 'author'],
            rest_base: 'posts',
            rest_namespace: 'wp/v2',
          },
          {
            description: '',
            hierarchical: true,
            has_archive: false,
            public: true,
            name: 'Pages',
            slug: 'page',
            icon: 'dashicons-admin-page',
            taxonomies: [],
            supports: ['title', 'editor', 'author'],
            rest_base: 'pages',
            rest_namespace: 'wp/v2',
          },
          {
            description: '',
            hierarchical: false,
            has_archive: true,
            public: true,
            name: 'Bio',
            slug: 'bio',
            icon: null,
            taxonomies: [],
            supports: ['title', 'editor', 'author', 'revisions'],
            rest_base: 'bio',
            rest_namespace: 'wp/v2',
          },
          {
            description: '',
            hierarchical: false,
            has_archive: true,
            public: true,
            name: 'News',
            slug: 'news',
            icon: null,
            taxonomies: [],
            supports: [
              'title',
              'editor',
              'author',
              'thumbnail',
              'revisions',
              'comments',
            ],
            rest_base: 'news',
            rest_namespace: 'wp/v2',
          },
        ],
        taxonomies: [
          {
            name: 'Categories',
            slug: 'category',
            description: '',
            types: ['post'],
            hierarchical: true,
          },
          {
            name: 'Tags',
            slug: 'post_tag',
            description: '',
            types: ['post'],
            hierarchical: false,
          },
          {
            name: 'Group',
            slug: 'group',
            description: '',
            types: ['post', 'news', 'bio'],
            hierarchical: false,
          },
          {
            name: 'Department',
            slug: 'department',
            description: '',
            types: ['bio'],
            hierarchical: true,
          },
        ],
        menus: [
          {
            name: 'Header Menu',
            slug: 'header-menu',
            id: 3,
          },
          {
            name: 'Header Right Menu',
            slug: 'header-right-menu',
            id: 4,
          },
          {
            name: 'Footer Menu',
            slug: 'footer-menu',
            id: 5,
          },
          {
            name: 'Sidebar Menu',
            slug: 'sidebar-menu',
            id: 6,
          },
        ],
        field_groups: [
          {
            name: 'Page Settings',
            slug: 'page_settings_group',
            key: 'group_fbb03f883701982327691c0a',
            fields: [
              {
                key: 'field_56c25a5e61e80e5ede4d4d21',
                label: 'Title',
                name: 'page_title',
                type: 'text',
                required: 1,
                instructions: '',
                default_value: '',
              },
              {
                key: 'field_a398ee4ee6ca0d85907988e8',
                label: 'Subtitle',
                name: 'page_subtitle',
                type: 'text',
                required: 0,
                instructions: '',
                default_value: '',
              },
              {
                key: 'field_44dd26542f37bcb07a38a0fc',
                label: 'Breadcrumb',
                name: 'page_breadcrumb',
                type: 'text',
                required: 0,
                instructions: '',
                default_value: '',
              },
              {
                key: 'field_2c326c36ed479077d82a0fae',
                label: 'Rating',
                name: 'page_rating',
                type: 'number',
                required: 0,
                instructions: '',
                default_value: 1,
              },
              {
                key: 'field_2c326c36ed479031d82a0fae',
                label: 'Show disclaimer',
                name: 'page_disclaimer',
                type: 'true_false',
                required: 0,
                instructions: '',
                default_value: 1,
              },
            ],
            location: [
              [
                {
                  param: 'post_type',
                  operator: '==',
                  value: 'page',
                },
                {
                  param: 'post_status',
                  operator: '!=',
                  value: 'draft',
                },
              ],
            ],
          },
        ],
      },
      2: {
        name: 'Project 2',
        url: 'rgbc2.com',
        loaded: true,
        selectedEntities: [],
        types: [
          {
            description: '',
            hierarchical: false,
            has_archive: true,
            public: true,
            name: 'Posts',
            slug: 'post',
            icon: 'dashicons-admin-post',
            taxonomies: ['category', 'post_tag'],
            supports: ['thumbnail', 'title', 'editor', 'author'],
            rest_base: 'posts',
            rest_namespace: 'wp/v2',
          },
          {
            description: '',
            hierarchical: true,
            has_archive: false,
            public: true,
            name: 'Pages',
            slug: 'page',
            icon: 'dashicons-admin-page',
            taxonomies: [],
            supports: ['thumbnail', 'title', 'editor', 'author'],
            rest_base: 'pages',
            rest_namespace: 'wp/v2',
          },
          {
            description: '',
            hierarchical: false,
            has_archive: false,
            public: true,
            name: 'Team',
            slug: 'team',
            icon: null,
            taxonomies: [],
            supports: ['title', 'editor', 'author', 'revisions'],
            rest_base: 'team',
            rest_namespace: 'wp/v2',
          },
        ],
        taxonomies: [],
        menus: [],
        field_groups: [],
      },
    },
    setProjectProperty: (projectId, property, value) =>
      set((state) => {
        if (!state.projects[projectId]) {
          return state; // Return the current state if the project does not exist
        }

        // Make a copy of the current projects object
        const updatedProjects = { ...state.projects };

        // Update the project's property
        updatedProjects[projectId] = {
          ...updatedProjects[projectId],
          [property]: value,
        };

        // Return the updated state
        return { ...state, projects: updatedProjects };
      }),
    changeEntityProperty: (
      projectId,
      entityName,
      entityID,
      propertyName,
      newValue
    ) =>
      set((state) => {
        if (
          state.projects[projectId] &&
          state.projects[projectId][entityName] &&
          state.projects[projectId][entityName][entityID]
        ) {
          // clone the entities array
          const updatedEntities = [...state.projects[projectId][entityName]];

          // update the property of the specific entity
          updatedEntities[entityID] = {
            ...updatedEntities[entityID],
            [propertyName]: newValue,
          };

          return {
            ...state,
            projects: {
              ...state.projects,
              [projectId]: {
                ...state.projects[projectId],
                [entityName]: updatedEntities,
              },
            },
          };
        }
        return state;
      }),
    addEntity: (projectId, entityGroup, newEntityData = null) =>
      set((state) => {
        if (
          state.projects[projectId] &&
          state.projects[projectId][entityGroup]
        ) {
          if (!newEntityData) {
            newEntityData = getDefaultData()?.[entityGroup] || {};
          }
          // Clone the entities array
          const updatedEntities = [...state.projects[projectId][entityGroup]];

          // Add the new entity to the array
          updatedEntities.push(newEntityData);

          return {
            ...state,
            projects: {
              ...state.projects,
              [projectId]: {
                ...state.projects[projectId],
                [entityGroup]: updatedEntities,
              },
            },
          };
        }
        return state;
      }),
    removeEntity: (projectId, entityGroup, entityId) =>
      set((state) => {
        if (state.projects[projectId]) {
          // Clone the array
          const updatedEntities = [...state.projects[projectId][entityGroup]];

          // Remove the post type at the specified index
          updatedEntities.splice(entityId, 1);

          return {
            ...state,
            projects: {
              ...state.projects,
              [projectId]: {
                ...state.projects[projectId],
                [entityGroup]: updatedEntities,
              },
            },
          };
        }
        return state;
      }),
    selectEntity: (projectId, entityID) =>
      set((state) => {
        const project = state.projects[projectId];
        if (project) {
          return {
            projects: {
              ...state.projects,
              [projectId]: {
                ...project,
                selectedEntities: [...project.selectedEntities, entityID],
              },
            },
          };
        }
      }),
    unselectEntity: (projectId, entityID) =>
      set((state) => {
        const project = state.projects[projectId];
        if (project) {
          return {
            projects: {
              ...state.projects,
              [projectId]: {
                ...project,
                selectedEntities: project.selectedEntities.filter(
                  (t) => t !== entityID
                ),
              },
            },
          };
        }
      }),
    resetSelectedEntities: (projectId) =>
      set((state) => {
        const project = state.projects[projectId];
        if (project) {
          return {
            projects: {
              ...state.projects,
              [projectId]: {
                ...project,
                selectedEntities: [],
              },
            },
          };
        }
      }),
    removeLocationRule: (
      projectId,
      fieldGroupIndex,
      locationGroupIndex,
      locationRuleIndex
    ) =>
      set((state) => {
        const project = state.projects[projectId];
        if (!project) return state;

        const fieldGroup = project.field_groups[fieldGroupIndex];
        if (!fieldGroup) return state;

        const locationGroup = fieldGroup.location[locationGroupIndex];
        if (!locationGroup) return state;

        // If it's the last location rule in the last location group, do nothing.
        if (fieldGroup.location.length === 1 && locationGroup.length === 1) {
          return state;
        }

        locationGroup.splice(locationRuleIndex, 1);

        if (locationGroup.length === 0) {
          fieldGroup.location.splice(locationGroupIndex, 1);
        } else {
          fieldGroup.location[locationGroupIndex] = [...locationGroup];
        }

        const updatedFieldGroups = [...project.field_groups];
        updatedFieldGroups[fieldGroupIndex] = { ...fieldGroup };

        const updatedProjects = { ...state.projects };
        updatedProjects[projectId] = {
          ...project,
          field_groups: updatedFieldGroups,
        };

        return { projects: updatedProjects };
      }),
    addLocationRule: (
      projectId,
      fieldGroupIndex,
      locationGroupIndex,
      locationRuleIndex,
      locationRule = {
        param: 'post_type',
        operator: '==',
        value: 'post',
      }
    ) => {
      set((state) => {
        const fieldGroup =
          state.projects[projectId]?.field_groups[fieldGroupIndex];
        if (!fieldGroup) {
          console.error('Field Group not found!');
          return state;
        }

        const locationGroup = fieldGroup.location[locationGroupIndex];
        if (!locationGroup) {
          console.error('Location Group not found!');
          return state;
        }

        // Insert the new location rule after the given location rule index
        locationGroup.splice(locationRuleIndex + 1, 0, locationRule);

        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              field_groups: state.projects[projectId].field_groups.map(
                (fg, i) =>
                  i === fieldGroupIndex
                    ? {
                        ...fg,
                        location: fg.location.map((lg, j) =>
                          j === locationGroupIndex ? locationGroup : lg
                        ),
                      }
                    : fg
              ),
            },
          },
        };
      });
    },
    addLocationGroup: (projectId, fieldGroupIndex) => {
      set((state) => {
        const fieldGroup =
          state.projects[projectId]?.field_groups[fieldGroupIndex];
        if (!fieldGroup) {
          console.error('Field Group not found!');
          return state;
        }

        // Add a new, empty location group
        fieldGroup.location.push([
          {
            param: 'post_type',
            operator: '==',
            value: 'post',
          },
        ]);

        // Return a new state with the updated project data
        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              field_groups: state.projects[projectId].field_groups.map(
                (fg, i) =>
                  i === fieldGroupIndex
                    ? { ...fg, location: [...fg.location] }
                    : fg
              ),
            },
          },
        };
      });
    },
    changeRuleProperty: (
      projectId,
      fieldGroupIndex,
      locationGroupIndex,
      locationRuleIndex,
      propertyName,
      value
    ) => {
      set((state) => {
        const fieldGroup =
          state.projects[projectId]?.field_groups[fieldGroupIndex];
        if (!fieldGroup) {
          console.error('Field Group not found!');
          return state;
        }

        const locationGroup = fieldGroup.location[locationGroupIndex];
        if (!locationGroup) {
          console.error('Location Group not found!');
          return state;
        }

        const rule = locationGroup[locationRuleIndex];
        if (!rule) {
          console.error('Rule not found!');
          return state;
        }

        if (!rule.hasOwnProperty(propertyName)) {
          console.error('Invalid property name!');
          return state;
        }

        // Update the property value
        rule[propertyName] = value;

        // Return a new state with the updated project data
        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              field_groups: state.projects[projectId].field_groups.map(
                (fg, i) =>
                  i === fieldGroupIndex
                    ? {
                        ...fg,
                        location: fg.location.map((locGroup, locIndex) =>
                          locIndex === locationGroupIndex
                            ? locGroup.map((locRule, ruleIndex) =>
                                ruleIndex === locationRuleIndex
                                  ? { ...locRule, [propertyName]: value }
                                  : locRule
                              )
                            : locGroup
                        ),
                      }
                    : fg
              ),
            },
          },
        };
      });
    },
    removeFieldByIndex: (projectId, fieldGroupIndex, fieldIndex) => {
      set((state) => {
        // Check for project existence
        if (!state.projects[projectId]) {
          return state;
        }

        // Check for field group existence
        const fieldGroup =
          state.projects[projectId].field_groups[fieldGroupIndex];
        if (!fieldGroup) {
          return state;
        }

        // Remove the field at the given index
        fieldGroup.fields.splice(fieldIndex, 1);

        // Return updated state
        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              field_groups: [...state.projects[projectId].field_groups],
            },
          },
        };
      });
    },
    addField: (projectId, fieldGroupIndex) => {
      set((state) => {
        const updatedProjects = { ...state.projects };
        const fieldGroup =
          updatedProjects?.[projectId]?.field_groups?.[fieldGroupIndex];
        if (!fieldGroup) {
          return state;
        }
        const newField = getDefaultData().field;
        updatedProjects[projectId].field_groups[fieldGroupIndex].fields.push(
          newField
        );

        // Determine the new field's index
        const newFieldIndex =
          updatedProjects[projectId].field_groups[fieldGroupIndex].fields
            .length - 1;

        return {
          ...state,
          projects: updatedProjects,
          newFieldIndex, // This can be a temporary property just to get the value out
        };
      });
    },
    duplicateField: (projectId, fieldGroupIndex, fieldIndex) => {
      set((state) => {
        // Check for project existence
        if (!state.projects[projectId]) {
          return state;
        }

        // Check for field group existence
        const fieldGroup =
          state.projects[projectId].field_groups[fieldGroupIndex];
        if (!fieldGroup) {
          return state;
        }

        // Get the field to be duplicated
        const fieldToDuplicate = fieldGroup.fields[fieldIndex];
        if (!fieldToDuplicate) {
          return state;
        }

        // Duplicate the field
        const duplicatedField = {
          ...fieldToDuplicate,
          key: `field_${uuid()}`,
          name: `${fieldToDuplicate.name}_copy_${uuid()}`,
        };

        // Insert the duplicated field after the clicked item
        fieldGroup.fields.splice(fieldIndex + 1, 0, duplicatedField);

        // Return updated state
        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              field_groups: [...state.projects[projectId].field_groups],
            },
          },
        };
      });
    },
    changeFieldProp: (projectId, fieldGroupIndex, fieldIndex, prop, value) => {
      set((state) => {
        const newState = { ...state };
        newState.projects[projectId].field_groups[fieldGroupIndex].fields[
          fieldIndex
        ][prop] = value;
        return newState;
      });
    },
  }))
);
