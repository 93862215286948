import React from 'react';

const Textarea = ({ value, label, onChange, placeholder, rows = 4 }) => {
  return (
    <div className="field-row mb-4">
      <label className="block">
        {label && <span className="text-sm text-gray-700">{label}</span>}
        <textarea
          className="mt-1 py-1 text-sm block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
        />
      </label>
    </div>
  );
};

export default Textarea;
