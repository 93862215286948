import { useMemo } from 'react';
import Select from 'react-select';
import { useProjectsStore } from '../../store';
import dashicons from './dashicons.json';

import './Dashicons.scss';

const Dashicons = ({ project, entityID }) => {
  const projects = useProjectsStore((state) => state.projects);
  const entity = projects[project.id].types?.[entityID];
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  const options = useMemo(
    () =>
      dashicons.map((icon) => ({
        value: icon,
        label: icon,
      })),
    []
  );

  const value = useMemo(
    () =>
      options.find((option) => option.value === (entity.icon || 'admin-post')),
    [options, entity]
  );
  return (
    <div className="mb-4 flex items-end">
      <div className="w-9 h-9 rounded-md shadow-lg mr-2 mb-0.5 inline-flex items-center justify-center">
        <span
          className={`dashicon dashicons dashicons-${
            entity.icon || 'admin-post'
          }`}
        ></span>
      </div>
      <div className="flex-1">
        <div className="text-sm text-gray-700 mb-1">Icon</div>
        <Select
          options={options}
          onChange={(opt) => {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'icon',
              opt.value
            );
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default Dashicons;
