import Field from './Field';
import Button from '../../../components/Button';
import { useProjectsStore } from '../../../store';

const FieldGroup = ({ project, group }) => {
  const { addField, newFieldIndex } = useProjectsStore();
  return (
    <div>
      <table className="table-fixed border-collapse w-full text-sm relative">
        <thead className="bg-slate-100 sticky left-0 top-0 z-10">
          <tr>
            <th className="p-2 text-left">
              <Button
                text="Add"
                isSmall
                onClick={() => {
                  addField(project.id, group.index);
                }}
              />
            </th>
            <th className="p-2 text-left">Label</th>
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Type</th>
            <th className="p-2 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {group?.fields?.map((field, index) => {
            return (
              <Field
                key={field.key}
                field={field}
                index={index}
                project={project}
                group={group}
                newField={newFieldIndex || null}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FieldGroup;
