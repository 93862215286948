import './ModernCheckboxes.scss';

const ModernCheckboxes = ({ options = [], value, label = null, onChange }) => {
  return (
    <>
      {label && <h5 className="mb-2 text-sm">{label}</h5>}
      <div className="flex flex-wrap gap-1">
        {options.map((option) => {
          return (
            <label
              key={option}
              className="inline-flex cursor-pointer modern-checkbox"
            >
              <input
                type="checkbox"
                className="hidden"
                checked={value.includes(option)}
                onChange={onChange}
                value={option}
              />
              <span className="inline-flex rounded-xl border border-solid border-indigo-600 text-indigo-600 px-2 py-1 text-sm transition-colors">
                {option}
              </span>
            </label>
          );
        })}
      </div>
    </>
  );
};

export default ModernCheckboxes;
