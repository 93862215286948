import classNames from 'classnames';
import './Checkbox.scss';

const Checkbox = (props) => {
  const {
    label,
    labelLeft,
    checked,
    onChange,
    isReadonly,
    switcher = false,
    title,
    classes,
  } = props;

  return (
    <label
      className={classNames(
        'inline-flex items-center cursor-pointer',
        classes,
        {
          'pointer-events-none opacity-30': isReadonly,
          switcher: switcher,
        }
      )}
    >
      {label && labelLeft && (
        <span className="text-sm text-gray-700 mr-2">{label}</span>
      )}
      <input
        type="checkbox"
        className={classNames(
          'rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50',
          {
            hidden: switcher,
          }
        )}
        checked={checked}
        onChange={onChange}
        readOnly={isReadonly}
        title={title}
      />
      {switcher && (
        <span className="switcher--toggle inline-flex w-8 h-4 border-indigo-600 border-2 rounded-lg relative border-solid transition-colors"></span>
      )}
      {label && !labelLeft && (
        <span className="text-sm text-gray-700 ml-2">{label}</span>
      )}
    </label>
  );
};

export default Checkbox;
