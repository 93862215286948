const Input = (props) => {
  const { type, label, placeholder, value, onChange, isReadonly, sanitize } =
    props;

  const handleOnChange = (event) => {
    let newValue = event.target.value;

    if (sanitize === 'slug') {
      newValue = newValue.toLowerCase();

      // Replace spaces with dashes
      newValue = newValue.replace(/\s/g, '-');

      // Remove any non-alphanumeric or non-dash characters
      newValue = newValue.replace(/[^a-z0-9\-_]/g, '');
    } else if (sanitize === 'url') {
      // Convert to lowercase
      newValue = newValue.toLowerCase();

      // Replace spaces with dashes
      newValue = newValue.replace(/\s/g, '');

      // Remove any non-alphanumeric, non-dash, non-underscore, or non-URL-friendly characters
      newValue = newValue.replace(/[^a-z0-9\-_\.~:\/?#\[\]@!$&'()*+,;=%]/g, '');
    }

    onChange({ ...event, target: { ...event.target, value: newValue } });
  };

  return (
    <div className="field-row mb-4">
      <label className="block">
        {label && <span className="text-sm text-gray-700">{label}</span>}
        <input
          type={type}
          className="mt-1 py-1 text-sm block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          readOnly={isReadonly}
        />
      </label>
    </div>
  );
};

export default Input;
