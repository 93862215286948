import params from './rule-params.json';
import statuses from './post-status.json';
import userOpts from './user.json';
import Select from 'react-select';
import { useMemo } from 'react';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid';
import { useProjectsStore } from '../../../../store';

const { current_user, user_role, user_form } = userOpts;

// Flatten the params array.
const flatParams = params.reduce((acc, group) => {
  return acc.concat(group.options);
}, []);

const operators = [
  {
    label: 'is equal to',
    value: '==',
  },
  {
    label: 'is not equal to',
    value: '!=',
  },
];

const Rule = ({ project, rule, ruleIndex, ruleGroupIndex, fieldGroup }) => {
  const removeLocationRule = useProjectsStore(
    (state) => state.removeLocationRule
  );
  const addLocationRule = useProjectsStore((state) => state.addLocationRule);
  const changeRuleProperty = useProjectsStore(
    (state) => state.changeRuleProperty
  );
  const paramValue = useMemo(() => {
    return flatParams.find((option) => option.value === rule.param);
  }, [rule.param]);
  const changeValue = (val) => {
    changeRuleProperty(
      project.id,
      fieldGroup.id,
      ruleGroupIndex,
      ruleIndex,
      'value',
      val
    );
  };
  const values = useMemo(() => {
    let values = [];
    switch (paramValue?.value) {
      case 'post_type':
        values = project.types.reduce((result, type) => {
          return [...result, { value: type.slug, label: type.name }];
        }, []);
        break;
      case 'post_status':
        values = statuses;
        break;
      case 'post_taxonomy':
        values = project.taxonomies
          .filter((taxonomy) => {
            return taxonomy.types.includes('post');
          })
          .reduce((result, taxonomy) => {
            return [...result, { value: taxonomy.slug, label: taxonomy.name }];
          }, []);
        break;
      case 'current_user':
        values = current_user;
        break;
      case 'current_user_role':
      case 'user_role':
        values = user_role;
        break;
      case 'user_form':
        values = user_form;
        break;
      case 'taxonomy':
        values = project.taxonomies.reduce((result, taxonomy) => {
          return [...result, { value: taxonomy.slug, label: taxonomy.name }];
        }, []);
        break;
      case 'attachment':
        values = [{ value: 'all', label: 'All' }];
        break;
      case 'nav_menu':
      case 'nav_menu_item':
        values = project.menus.reduce((result, menu) => {
          return [...result, { value: menu.id, label: menu.name }];
        }, []);
        break;
      default:
        values = [];
        break;
    }
    // Preselect default value.
    if (values.length) {
      changeValue(values[0]?.value);
    }
    return values;
  }, [paramValue]);
  const ruleVal = useMemo(() => {
    const find = values.find((option) => option.value === rule.value) || null;
    return find;
  }, [values, rule.value]);
  return (
    <div className="flex mb-4">
      <div className="flex-1">
        <Select
          options={params}
          className="mb-2"
          defaultValue={params[0].options[0]}
          value={paramValue}
          onChange={(opt) => {
            changeRuleProperty(
              project.id,
              fieldGroup.id,
              ruleGroupIndex,
              ruleIndex,
              'param',
              opt.value
            );
          }}
        />
        <Select
          options={operators}
          className="mb-2"
          defaultValue={operators[0]}
          value={operators.find((option) => option.value === rule.operator)}
          onChange={(opt) => {
            changeRuleProperty(
              project.id,
              fieldGroup.id,
              ruleGroupIndex,
              ruleIndex,
              'operator',
              opt.value
            );
          }}
        />
        <Select
          options={values}
          className="mb-2"
          value={ruleVal}
          onChange={(opt) => {
            changeValue(opt.value);
          }}
        />
      </div>
      <div className="flex flex-col items-center justify-center w-8 p-2">
        {(fieldGroup.location?.length > 1 ||
          (fieldGroup.location[0]?.length || 0) > 1) && (
          <button
            onClick={() => {
              removeLocationRule(
                project.id,
                fieldGroup.id,
                ruleGroupIndex,
                ruleIndex
              );
            }}
          >
            <MinusCircleIcon className="h-6 w-6 mb-4 text-gray-400 hover:text-rose-500" />
          </button>
        )}
        <button
          onClick={() => {
            addLocationRule(
              project.id,
              fieldGroup.id,
              ruleGroupIndex,
              ruleIndex
            );
          }}
        >
          <PlusCircleIcon className="h-6 w-6 text-gray-400 hover:text-teal-500" />
        </button>
      </div>
    </div>
  );
};

export default Rule;
