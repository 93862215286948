import React from 'react';
import Input from '../../../components/Input';
import { useProjectsStore } from '../../../store';
import GroupLocation from './ACF/GroupLocation';

const AcfSettings = ({ project, entityID }) => {
  const projects = useProjectsStore((state) => state.projects);
  const entity = projects[project.id].field_groups?.[entityID];
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  return (
    <div className="flex flex-col">
      <Input
        type="text"
        value={entity?.name || ''}
        label="Name"
        onChange={(e) => {
          changeEntityProperty(
            project.id,
            'field_groups',
            entityID,
            'name',
            e.target.value
          );
        }}
      />
      <GroupLocation project={project} entity={{ ...entity, id: entityID }} />
    </div>
  );
};

export default AcfSettings;
