import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/solid';
import structure from './structure.json';

const Sep = () => <span className="px-2">|</span>;

const NoLink = ({ title = '' }) => (
  <span>
    <span>{title}</span>
  </span>
);

const Link = ({ title = '', to = '/', Icon }) => {
  const navigate = useNavigate();

  return (
    <button
      className="inline-flex items-center"
      onClick={() => navigate(to)}
      title={title}
    >
      {Icon && <Icon className="h-4 w-4 mr-2" />}
      <span>{title}</span>
    </button>
  );
};

const Pagination = ({ project, group }) => {
  return (
    <div className="flex h-9 pb-4 border-b -mx-4 px-4 text-sm">
      <Link title="Projects" Icon={HomeIcon} />
      {project && (
        <>
          <Sep />
          <Link title={project.name} to={`/projects/${project.id}`} />
        </>
      )}
      {project.view && (
        <>
          <Sep />
          {project.view === 'field_groups' && (
            <>
              <Link
                title={structure?.[project.view] || ''}
                to={`/projects/${project.id}/field_groups`}
              />
              {group && (
                <>
                  <Sep />
                  <NoLink title={group?.name || ''} />
                </>
              )}
            </>
          )}
          {project.view !== 'field_groups' && (
            <NoLink title={structure?.[project.view] || ''} />
          )}
        </>
      )}
    </div>
  );
};

export default Pagination;
