import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import classNames from 'classnames';
import { useSidebarStore } from '../../store';

const Sidebar = () => {
  const { content } = useContext(SidebarContext);
  const isOpen = useSidebarStore((state) => state.isOpen);
  return (
    <div
      className={classNames(
        'fixed px-4 py-2 w-80 bg-white shadow-md h-full top-0 transition-all overflow-y-auto',
        {
          '-right-80': isOpen === false,
          'right-0': isOpen !== false,
        }
      )}
    >
      {content}
    </div>
  );
};

export default Sidebar;
