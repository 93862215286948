import { Link } from 'react-router-dom';
import { useProjectsStore } from '../../store';

function Projects() {
  const projects = useProjectsStore((state) => state.projects);

  return (
    <div className="overflow-hidden rounded-md bg-white shadow">
      <ul className="divide-y divide-gray-200">
        {Object.entries(projects).map(([id, project]) => (
          <li key={id} className="px-6 py-4">
            <Link to={`/projects/${id}`}>{project.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Projects;
