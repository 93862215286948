import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useSidebarStore = create(
  devtools((set) => ({
    isOpen: false,
    openSidebar: (entityID) => set((state) => ({ isOpen: entityID })),
    closeSidebar: () => set((state) => ({ isOpen: false })),
  }))
);
