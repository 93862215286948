import Input from '../../../components/Input';
import Dashicons from '../../../components/Dashicons';
import ModernCheckboxes from '../../../components/ModernCheckboxes';
import { useProjectsStore } from '../../../store';

const SUPPORTS = [
  'title',
  'editor',
  'author',
  'thumbnail',
  'excerpt',
  'trackbacks',
  'custom-fields',
  'comments',
  'revisions',
  'page-attributes',
  'post-formats',
];

const PostTypeSettings = ({ isDefault, project, entityID }) => {
  const projects = useProjectsStore((state) => state.projects);
  const entity = projects[project.id].types?.[entityID];
  const changeEntityProperty = useProjectsStore(
    (state) => state.changeEntityProperty
  );
  return (
    <div className="flex flex-col">
      <Input
        type="text"
        value={entity?.slug || ''}
        label="Slug"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'slug',
              e.target.value
            );
          }
        }}
        sanitize="slug"
      />
      <Input
        type="text"
        value={entity?.name || ''}
        label="Name"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'name',
              e.target.value
            );
          }
        }}
      />
      <Input
        type="text"
        value={entity?.singular_name || ''}
        label="Singular name"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'singular_name',
              e.target.value
            );
          }
        }}
      />
      <Input
        type="text"
        value={entity?.menu_name || ''}
        label="Menu name"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'menu_name',
              e.target.value
            );
          }
        }}
      />
      <Input
        type="text"
        value={entity?.rewrite || ''}
        label="Rewrite"
        isReadonly={isDefault}
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'rewrite',
              e.target.value
            );
          }
        }}
      />
      <Dashicons project={project} entityID={entityID} />
      <ModernCheckboxes
        options={SUPPORTS}
        value={entity?.supports || []}
        label="Supports:"
        onChange={(e) => {
          if (!isDefault) {
            changeEntityProperty(
              project.id,
              'types',
              entityID,
              'supports',
              e.target.checked
                ? [...entity?.supports, e.target.value]
                : entity.supports.filter(
                    (support) => support !== e.target.value
                  )
            );
          }
        }}
      />
    </div>
  );
};

export default PostTypeSettings;
