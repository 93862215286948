// NotFound.js
import React from 'react';
import { BugAntIcon } from '@heroicons/react/24/solid';

function NotFound() {
  return (
    <h2 className="text-lg text-slate-700 text-center">
      <BugAntIcon className="w-8 h-8 mx-auto mb-2" />
      404: Page Not Found
    </h2>
  );
}

export default NotFound;
