import classNames from 'classnames';
import { useProjectsStore, useSidebarStore } from '../../store';
import { useNavigate, useLocation } from 'react-router-dom';
import structure from './structure.json';
import Button from '../../components/Button';
import { registerAll } from '../../helpers';
import { saveAs } from 'file-saver';

const Structure = ({ project }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetSelectedEntities = useProjectsStore(
    (state) => state.resetSelectedEntities
  );
  const closeSidebar = useSidebarStore((state) => state.closeSidebar);

  return (
    <div className="w-48 border-r h-full border-slate-200 border-solid pt-4 pb-4">
      <h3 className="font-semibold">Structure</h3>
      <ul className="pl-2 mt-2">
        {Object.entries(structure).map(([value, label]) => {
          const path = `/projects/${project.id}/${value}`;
          const current = location.pathname.includes(path);
          return (
            <li className="mb-1" key={value}>
              <button
                type="button"
                className={classNames('hover:text-sky-400', {
                  'text-sky-600 pointer-events-none': current,
                })}
                disabled={current}
                onClick={() => {
                  if (!current) {
                    resetSelectedEntities(project.id);
                    closeSidebar();
                    navigate(path);
                  }
                }}
              >
                {label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="mt-4">
        <Button
          text="Export all"
          onClick={() => {
            const code = registerAll(project);
            const blob = new Blob([code], {
              type: 'text/plain;charset=utf-8',
            });
            saveAs(blob, `${project.name}.php`);
          }}
        />
      </div>
    </div>
  );
};

export default Structure;
